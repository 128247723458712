<template>
  <CNavbar :sticky="true" expandable="md" class="p3 shadow" type="dark">
    <CToggler in-navbar @click="collapsed = !collapsed" id="toogleButton">
      <font-awesome-icon icon="bars" size="lg"
    /></CToggler>
    <CNavbarBrand class="ml-auto mr-auto" href="#" @click="backToHomePage()">
      <img style="height: 30px" src="@/assets/images/d-dot-logo.svg" alt />
    </CNavbarBrand>
    <div
      v-if="$store.state.isShowCloseButtonChatOrder"
      class="panel-close-profile-order pb-1 pt-2"
    >
      <span
        @click="$store.commit('UPDATE_SHOW_CLOSE_BUTTON_CHATORDER', false)"
        class="cursor-pointer my-auto"
      >
        <font-awesome-icon :icon="['fas', 'times']" class="" />
        ปิด
      </span>
    </div>
    <CCollapse :show="collapsed" navbar>
      <CNavbarNav class="ml-auto my-2">
        <div
          class="ml-2"
          v-if="$route.path !== '/login' && $route.path !== '/AccessDenied'"
        >
          <b-button variant="light" @click="goToChat()">
            <font-awesome-icon class="mr-2 color-primary" icon="comments" />
            Chat
          </b-button>
        </div>
        <div
          class="ml-2"
          v-if="
            $route.path !== '/login' &&
              $route.path !== '/AccessDenied' &&
              isStore == 1
          "
        >
          <b-button variant="light" v-on:click="setShowModalExport(true)">
            <font-awesome-icon
              class="mr-2 color-primary"
              icon="download"
            />Export Sheet
          </b-button>
        </div>
        <div
          class="ml-2"
          v-if="$route.path !== '/login' && $route.path !== '/AccessDenied'"
        >
          <b-button variant="light" v-on:click="setShowModalImport(true)">
            <font-awesome-icon class="mr-2 color-primary" icon="upload" />Import
            Sheet
          </b-button>
        </div>
        <div
          class="ml-2"
          v-if="$route.path !== '/login' && $route.path !== '/AccessDenied'"
        >
          <b-dropdown
            id="dropdown-brand"
            :text="name"
            class=""
            variant="light"
            right
          >
            <b-dropdown-item
              v-for="(brand, bindex) in brandLists"
              :key="bindex"
              @click="onBrandChange(brand)"
            >
              <div class="d-flex w-100">
                {{ brand.name }}
              </div>
            </b-dropdown-item>
          </b-dropdown>
        </div>
        <div
          class="ml-2"
          v-if="$route.path !== '/login' && $route.path !== '/AccessDenied'"
        >
          <b-dropdown id="dropdown-logout" class="" variant="light" right>
            <template #button-content>
              <b-avatar class="user-icon px-2 py-1"></b-avatar>
              <span class="mx-2"> {{ username }}</span>
            </template>

            <b-dropdown-item @click="handleLogout">
              <font-awesome-icon
                class="mr-2 color-primary"
                icon="sign-out-alt"
              />Log out
            </b-dropdown-item>
          </b-dropdown>
        </div>
      </CNavbarNav>
    </CCollapse>
    <template v-if="forceRefresh">
      <div>
        <SelectBrand
          v-bind:isShow="isShowModalBrand"
          v-on:setShowModalBrand="setShowModalBrand"
          v-bind:onBrandChange="onBrandChange"
        />
        <ModalImport
          v-bind:isShow="isShowModalImport"
          v-bind:uploadTracking="uploadTracking"
          v-bind:shippingLists="shippingLists"
          v-on:setShowModalImport="setShowModalImport"
        />
        <ModalExportJobsheet
          v-bind:isShow="isShowModalExport"
          v-on:setShowModalExport="setShowModalExport"
        />
        <b-modal id="modal-1" v-model="result" hide-footer hide-header>
          <div class="m-3">
            <div class="mb-1">{{ resultMessage }}</div>
            <small
              class="text-danger"
              v-for="(item, index) in errorLists"
              :key="index + 'error'"
            >
              {{ item }}
            </small>
          </div>
        </b-modal>
      </div>
    </template>
  </CNavbar>
</template>

<script>
import SelectBrand from "../Modals/ModalChangeBrand";
import ModalImport from "../Modals/ModalImportTracking";
import ModalExportJobsheet from "../Modals/ModalExportJobSheet";
import { mapGetters } from "vuex";
import * as moment from "moment/moment";
export default {
  components: {
    SelectBrand,
    ModalImport,
    ModalExportJobsheet
  },
  data() {
    return {
      collapsed: false,
      isShowModalBrand: false,
      isShowModalExport: false,
      currentDate: "",
      currentTime: "",
      result: false,
      resultMessage: "",
      errorLists: [],
      //brandLists: [],
      shippingLists: [],
      brandId: null,
      textReg: /^\d+$/,
      config: {
        name: "BanilaCo"
      },
      forceRefresh: true,
      isShowModalImport: false,
      brandSelected: {
        id: 0,
        name: ""
      }
    };
  },
  mounted() {
    this.interval = setInterval(this.time, 1000);
  },

  beforeDestroy() {
    clearInterval(this.interval);
  },
  computed: {
    username: {
      get: function() {
        return this.$cookies.get("tnl-username");
      },
      set: function() {}
    },
    ...mapGetters({
      // map `this.doneCount` to `this.$store.getters.doneTodosCount`
      brandID: "getBrand",
      logo: "getBrandLogo",
      name: "getBrandName",
      mainColor: "getBrandColor",
      isStore: "getStoreFlag",
      brandLists: "getBrandLists"
    })
  },
  async created() {
    await this.getShippingType();
  },
  methods: {
    moment: function() {
      return moment();
    },
    goToChat() {
      var url = "/chat?brandID=" + this.brandID;
      window.open(url, "_blank");
    },
    async uploadTracking(
      ShippingId,
      base64ExcelString,
      selectedUpdateCustomer
    ) {
      this.setShowModalImport(false);
      this.$store.dispatch("setIsLoading", true);
      this.$axios
        .put(this.$baseUrl + "/api/transaction/importTrackingNo", {
          ShippingId: ShippingId,
          base64ExcelString: base64ExcelString,
          sendMessage: selectedUpdateCustomer
        })
        .then(response => {
          if (response.data.result === 1) {
            this.setShowModalImport(false);
            this.result = true;
            this.resultMessage = "อัพโหลดไฟล์สำเร็จ";
            this.errorLists = [];
            this.$store.dispatch("setIsLoading", false);
          } else {
            this.result = true;
            this.resultMessage = response.data.message;
            this.errorLists = response.data.detail.error;
            this.$store.dispatch("setIsLoading", false);
          }
        });
    },
    time() {
      this.currentDate = this.moment().format(this.$formatDate);
      this.currentTime = this.moment().format("HH:mm:ss");
    },
    getShippingType() {
      this.$axios
        .get(this.$baseUrl + "/api/transaction/shippingList")
        .then(response => {
          if (response.data.result === 1) {
            this.shippingLists = response.data.detail;
          } else {
            alert(response.data.message);
          }
        });
    },
    setShowModalImport(value) {
      this.handleForcefresh();
      this.isShowModalImport = value;
    },
    setShowModalExport(value) {
      this.handleForcefresh();
      this.isShowModalExport = value;
    },
    setShowModalBrand(value, brandSelected) {
      this.handleForcefresh();
      this.isShowModalBrand = value;
      if (!this.isShowModalBrand) {
        if (brandSelected) {
          if (brandSelected !== this.brandId) {
            this.$store.dispatch("setBrand", brandSelected);
            this.brandId = brandSelected;
            window.location.href = `/?brandID=${brandSelected}`;
          }
        }
      }
    },
    backToHomePage() {
      window.location.href = `/?brandID=${this.$route.query.brandID}`;
    },
    onBrandChange(brandSelected) {
      if (brandSelected.id !== this.brandId) {
        this.$store.dispatch("setBrand", brandSelected);
        this.brandId = brandSelected.id;
        window.location.href = `${this.$route.path}?brandID=${brandSelected.id}`;
      }
    },
    handleForcefresh() {
      this.forceRefresh = false;
      this.$nextTick(() => {
        this.forceRefresh = true;
        this.$root.$emit("setConfig");
      });
    },
    changeFavicon(src) {
      var link =
        document.querySelector("link[rel*='icon']") ||
        document.createElement("link");
      link.type = "image/x-icon";
      link.rel = "shortcut icon";
      link.href = src;
      document.getElementsByTagName("head")[0].appendChild(link);
    },
    async handleLogout() {
      var timestamp = new Date().getTime();

      this.$cookies.set("tnl-timestamp", timestamp);
      this.$store.dispatch("setTimestamp", timestamp);
      await this.$cookies.remove("tnl-session-token");
      await this.$cookies.remove("tnl-username");

      document.title = "Chat and Shop";
      await this.changeFavicon("../../assets/images/cmg.png");
      location.href = "/login";
      //await this.$router.push("/login");
    }
  }
};
</script>

<style>
.navbar {
  /* height: 55px; */
  background-color: white !important;
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}
.dropdown-setting {
  background-color: #fca2ca !important;
  border-color: #fca2ca !important;
  color: white;
}
.text-username {
  color: black;
}

#toogleButton {
  color: black;
}

#logout-nave {
  text-align: center;
}
#dropdown .btn {
  color: white;
}
.user-icon {
  background-color: rgba(113, 96, 196, 0.27);
  color: rgb(113, 96, 196);
}
.badge-primary {
  color: #fff;
  background-color: #832abf;
}
</style>
